import { lazy } from 'react';

// project import
import Loadable from './Loadable';
import SaleLayout from '../layout/SaleLayout';

// data limit manage for show on page
const pageDataLimit = 2;

const Sale = Loadable(lazy(() => import('../pages/Sale')));
const SaleCustomerScreen = Loadable(lazy(() => import('../pages/SaleCustomerScreen')));
const PageNotFound = Loadable(lazy(() => import('../pages/PageNotFound')));

// ==============================|| MAIN ROUTING ||============================== //

const title = 'MePOS';

const AdminRoutes = {
    element: <SaleLayout />,
    children: [
        {
            path: '/sale',
            element:
                <Sale
                    documentTitle={`Saller screen - ${title}`}
                    pageTitle='ຂາຍເຄື່ອງ'
                    menuActive='/sale'
                    pageDataLimit={pageDataLimit}
                />
        },
        {
            path: '/sale/customer/screen',
            element:
                <SaleCustomerScreen
                    documentTitle={`Sale cust screen - ${title}`}
                    pageTitle='ຂາຍເຄື່ອງ'
                    menuActive='/sale/customer/screen'
                    pageDataLimit={pageDataLimit}
                />
        },
        {
            path: '/*',
            element: (
                <PageNotFound
                    documentTitle={`Page Not Found - ${title}`}
                    pageTitle=''
                    menuActive=''
                />
            )
        }
    ]
};

export default AdminRoutes;
