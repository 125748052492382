import { lazy } from 'react';

// project import
import Loadable from './Loadable';
import MainLayout from '../layout/AdminLayout';

// data limit manage for show on page
const pageDataLimit = 2;

const SaleAdmin = Loadable(lazy(() => import('../pages/SaleAdmin')));
const Product = Loadable(lazy(() => import('../pages/Product')));
const Customer = Loadable(lazy(() => import('../pages/Customer')));
const Branch = Loadable(lazy(() => import('../pages/Branch')));
const ProductFormAE = Loadable(lazy(() => import('../components/product/ProductFormAE')));
const ProductView = Loadable(lazy(() => import('../components/product/ProductView')));
const ProductGroup = Loadable(lazy(() => import('../pages/ProductGroup')));
const ProductType = Loadable(lazy(() => import('../pages/ProductType')));
const Stock = Loadable(lazy(() => import('../pages/Stock')));
const ProductOutofStock = Loadable(lazy(() => import('../pages/ProductOutofStock')));
const ProductStockExpire = Loadable(lazy(() => import('../pages/ProductStockExpire')));
const ProductStockExpired = Loadable(lazy(() => import('../pages/ProductStockExpired')));
const StockIn = Loadable(lazy(() => import('../pages/StockIn')));
const Purchaseorder = Loadable(lazy(() => import('../pages/Purchaseorder')));
const ProducStockBuyOrderFormA = Loadable(lazy(() => import('../components/purchaseorder/ProducStockPurchaseorderFormA')));
const ProducStockFormA = Loadable(lazy(() => import('../components/product_stock/ProducStockFormA')));
const Unit = Loadable(lazy(() => import('../pages/Unit')));
const Vat = Loadable(lazy(() => import('../pages/Vat')));
const ReceiptInfo = Loadable(lazy(() => import('../pages/Receipt_info')));
const Promotion = Loadable(lazy(() => import('../pages/Promotion')));
const ExchangeRate = Loadable(lazy(() => import('../pages/ExchangeRate')));
const User = Loadable(lazy(() => import('../pages/User')));
const Userrole = Loadable(lazy(() => import('../pages/User_role')));
const Profile = Loadable(lazy(() => import('../pages/Profile')));
// Report
const DialyReport = Loadable(lazy(() => import('../pages/report/DialyReport')));
const SaleReport = Loadable(lazy(() => import('../pages/report/SaleReport')));
const BuyReport = Loadable(lazy(() => import('../pages/report/BuyReport')));
const StockReport = Loadable(lazy(() => import('../pages/report/StockReport')));
//Page not found
const PageNotFound = Loadable(lazy(() => import('../pages/PageNotFound')));

/*
// without load affect
import User from '../pages/User';
import Userrole from '../pages/User_role';
import Profile from '../pages/Profile';
import PageNotFound from '../pages/PageNotFound';
*/

// ==============================|| MAIN ROUTING ||============================== //

const title = 'MePOS';

const AdminRoutes = {
    element: <MainLayout />,
    children: [
        /*
        {
            path: '/sale',
            element:
                <Sale
                    documentTitle='Sale - Tayai-GPOS'
                    pageTitle='ຂາຍເຄື່ອງ'
                    menuActive='/sale'
                    pageDataLimit={pageDataLimit}
                />
        },
        */
        {
            path: '/sale/admin',
            element:
                <SaleAdmin
                    documentTitle={`Sale admin screen - ${title}`}
                    pageTitle='ຂາຍເຄື່ອງ'
                    menuActive='/sale'
                    pageDataLimit={pageDataLimit}
                />
        },
        {
            path: '/product',
            element:
                <Product
                    documentTitle={`Product - ${title}`}
                    pageTitle='ຂໍ້ມູນສິນຄ້າ'
                    menuActive='/product'
                />
        }, {
            path: '/product/view/:mid/:prdIdEdit/:prd_no',
            element:
                <ProductView
                    documentTitle={`Product view - ${title}`}
                    pageTitle='ຂໍ້ມູນລາຍລະອຽດ'
                    menuActive='/product'
                />
        }, {
            path: '/product/addform/new',
            element:
                <ProductFormAE
                    documentTitle={`Product add - ${title}`}
                    pageTitle='ເພີ່ມຂໍ້ມູນສິນຄ້າ'
                    menuActive='/product'
                />
        }, {
            path: '/product/editform/:prdIdEdit',
            element:
                <ProductFormAE
                    documentTitle={`Product edit - ${title}`}
                    pageTitle='ເເກ້ໄຂຂໍ້ມູນສິນຄ້າ'
                    menuActive='/product'
                />
        }, {
            path: '/product/stock',
            element:
                <Stock
                    documentTitle={`Product stock - ${title}`}
                    pageTitle='ຂໍ້ມູນສາງສິນຄ້າ'
                    menuActive='/product/stock'
                />
        }, {
            path: '/product/outofstock',
            element:
                <ProductOutofStock
                    documentTitle={`Product out of stock - ${title}`}
                    pageTitle='ສິນຄ້າໄກ້ໝົດສາງ'
                    menuActive='/product/outofstock'
                />
        }, {
            path: '/product/stock/expire',
            element:
                <ProductStockExpire
                    documentTitle={`Product stock expire - ${title}`}
                    pageTitle='ຂໍ້ມູນສິນຄ້າໄກ້ໝົດອາຍຸ'
                    menuActive='/product/stock/expire'
                />
        }, {
            path: '/product/stock/expired',
            element:
                <ProductStockExpired
                    documentTitle={`Product stock expired - ${title}`}
                    pageTitle='ຂໍ້ມູນສິນຄ້າໝົດອາຍຸ'
                    menuActive='/product/stock/expire'
                />
        }, {
            path: '/product/stock/in',
            element:
                <StockIn
                    documentTitle={`Product stock in - ${title}`}
                    pageTitle='ຂໍ້ມູນສາງສິນຄ້າ'
                    menuActive='/product/stock'
                />
        }, {
            path: '/product/stock/purchaseorder',
            element:
                <Purchaseorder
                    documentTitle={`Product stock purchaseorder - ${title}`}
                    pageTitle='ຂໍ້ມູນສາງສິນຄ້າ'
                    menuActive='/product/stock'
                />
        }, {
            path: '/product/stock/purchaseorder/add',
            element:
                <ProducStockBuyOrderFormA
                    documentTitle={`Product stock purchaseorder add - ${title}`}
                    pageTitle='ສ້າງໃບສັ່ງຊື້ສິນຄ້າ'
                    menuActive='/product/stock'
                />
        }, {
            path: '/product/stock/purchaseorder/edit/:purchaseorderid',
            element:
                <ProducStockBuyOrderFormA
                    documentTitle={`Product stock purchaseorder edit - ${title}`}
                    pageTitle='ສ້າງໃບສັ່ງຊື້ສິນຄ້າ'
                    menuActive='/product/stock/purchaseorder'
                />
        }, {
            path: '/product/stock/in/add',
            element:
                <ProducStockFormA
                    documentTitle={`Product stock add - ${title}`}
                    pageTitle='ເພີ່ມສິນຄ້າເຂົ້າສາງ'
                    menuActive='/product/stock'
                />
        }, {
            path: '/daily/report',
            element:
                <DialyReport
                    documentTitle={`Main Dashboard - ${title}`}
                    pageTitle='ລາຍງານລວມ'
                    menuActive='/daily/repor'
                />
        }, {
            path: '/sale/report',
            element:
                <SaleReport
                    documentTitle={`Sale report - ${title}`}
                    pageTitle='ລາຍງານການຂາຍ'
                    menuActive='/sale/report'
                />
        }, {
            path: '/buy/report',
            element:
                <BuyReport
                    documentTitle={`Buy report - ${title}`}
                    pageTitle='ລາຍງານການຊື້'
                    menuActive='/buy/report'
                />
        }, {
            path: '/product/stock/report',
            element:
                <StockReport
                    documentTitle={`Sale report - ${title}`}
                    pageTitle='ລາຍງານການຂາຍ'
                    menuActive='/sale/repor'
                />
        }, {
            path: '/customer',
            element:
                <Customer
                    documentTitle={`Customer - ${title}`}
                    pageTitle='ຂໍ້ມູນລູກຄ້າ'
                    menuActive='/customer'
                />
        }, {
            path: '/product/group',
            element:
                <ProductGroup
                    documentTitle={`Product group - ${title}`}
                    pageTitle='ຂໍ້ມູນກຸ່ມສິນຄ້າ'
                    menuActive='/product/group'
                />
        }, {
            path: '/product/type',
            element:
                <ProductType
                    documentTitle={`Product type - ${title}`}
                    pageTitle='ຂໍ້ມູນປະເພດສິນຄ້າ'
                    menuActive='/product/group'
                />
        }, {
            path: '/unit',
            element:
                <Unit
                    documentTitle={`Unit - ${title}`}
                    pageTitle='ຂໍ້ມູນຫົວໜ່ວຍ'
                    menuActive='/unit'
                />
        }, {
            path: '/vat',
            element:
                <Vat
                    documentTitle={`Vat - ${title}`}
                    pageTitle='ຂໍ້ມູນອາກອນມູນຄ່າເພີ່ມ'
                    menuActive='/vat'
                />
        }, {
            path: '/promotion',
            element:
                <Promotion
                    documentTitle={`Promotion - ${title}`}
                    pageTitle='ໂປໂມຊັ່ນ'
                    menuActive='/promotion'
                />
        }, {
            path: '/exchange-rate',
            element:
                <ExchangeRate
                    documentTitle={`Exchange-rate - ${title}`}
                    pageTitle='ອັດຕາເເລກປ່ຽນ'
                    menuActive='/exchange-rate'
                />
        }, {
            path: '/receipt_info',
            element:
                <ReceiptInfo
                    documentTitle={`ReceiptInfo - ${title}`}
                    pageTitle='ຂໍ້ມູນໃບບິນ'
                    menuActive='/receipt_info'
                />
        }, {
            path: '/branch',
            element:
                <Branch
                    documentTitle={`Branch - ${title}`}
                    pageTitle='ຂໍ້ມູນສາຂາ'
                    menuActive='/branch'
                />
        }, {
            path: '/user',
            element:
                <User
                    documentTitle={`User - ${title}`}
                    pageTitle='ຂໍ້ມູນຜູ້ໃຊ້ລະບົບ'
                    menuActive='/user'
                />
        }, {
            path: '/userrole',
            element:
                <Userrole
                    documentTitle={`User role - ${title}`}
                    pageTitle='ຂໍ້ມູນສິດຜູ້ໃຊ້'
                    menuActive='/userrole'
                />
        }, {
            path: '/profile',
            element:
                <Profile
                    documentTitle={`Profile - ${title}`}
                    pageTitle='ຂໍ້ມູນສ່ວນຕົວ'
                    menuActive='/profile'
                />
        }, {
            path: '/*',
            element: (
                <PageNotFound
                    documentTitle={`Page Not Found - ${title}`}
                    pageTitle=''
                    menuActive=''
                />
            )
        }
    ]
};

export default AdminRoutes;
