import { Fragment, Suspense, useContext } from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { grey, orange } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import Routes from './routes/route';
import './css/style.css';
import './font/Inter-3.19/font-inter.css';
import { ThemeContext } from "./Theme";
//import { colors } from '@mui/material';
//import { Padding } from '@mui/icons-material';

//const mainColor = '#3268e8';
//const mainColor = '#4f45e4';
const mainColor = '#2979ff';
const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'dark' ? {
      primary: {
        //main: '#2979ff',
        main: mainColor,
      },
      white: {
        main: '#ffffff'
      },
      background: {
        //default: '#1e2933',
        default: '#19222b',
        salepage: '#1e2933',
        leftmenu: '#1e2933', //1A242C
        //leftmenu: '#1d1e44',
        paper: '#1e2933',
        //paper: '#1d1e44',
        menu: '#39434b',
        table: '#202d38',
        footer: '#2a343e',
        orderfooter: "#32404c",
        orderMenu: "#32404c",
        orderDetail: '#1e2933',
        dialog: '#2d3842'
      },
      menu: {
        title: mainColor,
        selected: mainColor,

      },
      text: {
        fixed: mainColor,
        default: '#ffffff',
        primary: '#ffffff',
        info: '#2AB5F6',
        success: '#2F7C31',
        error: '#D32F2F',
        warning: orange[400],
        leftmenu: '#ffffff',
        secondary: grey[500],
        fieldset: '#c9cfd6',
        footer: '#ffffff'
      },
      boderColor: {
        default: '#32404c',
        mainfieldset: '#21303d',
        fieldset: '#32404c',
        menuOrder: '#32404c',
        line: '#32404c',
        tab: '#32404c'
      }
    } : {
      primary: {
        main: mainColor,
      },
      white: {
        main: '#ffffff'
      },
      background: {
        default: '#f2f4f7', //'#f9fafb', //'#ECF0F3', //'#ECF0F3', //'#f3f4f6'
        salepage: '#fefefe',
        leftmenu: '#f9fafb',
        //leftmenu: '#202D38',
        paper: '#ffffff',
        menu: '#ffffff',
        table: '#ffffff',
        footer: mainColor,
        orderfooter: "#e0e2e5", //"#e0dede",
        orderMenu: "#e8eaed",
        orderDetail: '#ffffff',
        dialog: '#ffffff'
      },
      menu: {
        title: mainColor,
        selected: mainColor,
      },
      text: {
        fixed: mainColor,
        default: '#3d4656',
        primary: '#3d4656',
        info: '#2AB5F6',
        success: '#2F7C31',
        error: '#D32F2F',
        warning: orange[900],
        //leftmenu: '#4a5568',
        leftmenu: '#303030',
        secondary: grey[800],
        footer: '#ffffff'
      },
      boderColor: {
        default: '#f0f0f0',
        mainfieldset: '#f0f0f0',
        fieldset: '#f4f4f4', //'#dbdbdb',
        menuOrder: '#e0e2e5',
        line: '#e0e0e0',
        tab: '#c9c9c9'
      }
    })
  },
  typography: {
    fontFamily: [
      'Inter', 'Noto Sans Lao' //, 'Phetsarath OT'
    ].join(','),
  }
});


function WrappedApp() {
  const { theme } = useContext(ThemeContext);

  const darkModeTheme = createTheme(getDesignTokens(theme));

  return (
    <ThemeProvider theme={darkModeTheme}>
      <CssBaseline />
      <Fragment>
        <GlobalStyles
          styles={{
            /* Set Image crop borderRadius*/
            '&.reactEasyCrop_CropArea': {
              borderRadius: '150px'
            },
            h1: { color: 'grey' },
            '*::-webkit-scrollbar': {
              width: '0.4em',
              height: '5px'
            },
            '*::-webkit-scrollbar-track': {
              /*background: "#fff",*/
            },
            '*::-webkit-scrollbar-thumb': {
              background: (theme == 'dark') ? '#37424c' : '#ccc',
              borderRadius: '25px'
            },
            '*::-webkit-scrollbar-thumb:hover': {
              background: (theme == 'dark') ? '#47525b' : '#c4c4c4',
            },
            '&.MuiBox-root.css-12kjrz': {
              marginLeft: 'auto',
              marginRight: 'auto',
            },
            // Set fontSize of image bar title
            '&.css-dasnyc-MuiImageListItemBar-title': {
              fontSize: '15px'
            },

            /******************* MUI datatable set *******************/
            '&.css-d6gbau-MuiPaper-root-MuiDialog-paper': {
              overflowX: 'none'
            },

            // Set MuiDatatable herder
            '&.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader': {
              background: '#F9FAFB'
            },
            // Set MuiDataTable header
            '&.MuiButtonBase-root': {
              fontFamily: 'Noto Sans Lao',
              //fontWeight: 'bold',
            },
            // MuiDatatable set icon
            '&.css-78trlr-MuiButtonBase-root-MuiIconButton-root': {
              color: (theme == 'dark') ? '#fff' : '#6c6c6c'
            },
            //MUIDataTablePagination button next and previous 
            '&.css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled': {
              color: (theme == 'dark') ? '#fff' : '#6c6c6c'
            },
            //MUIDataTablePagination button select rows show
            '&.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon': {
              color: (theme == 'dark') ? '#fff' : '#6c6c6c'
            },
            //MUIDataTable text search on header
            '&.css-1x51dt5-MuiInputBase-input-MuiInput-input': {
              color: (theme == 'dark') ? '#fff' : '#6c6c6c'
            },
            '&.MuiTableCell-sizeSmall': {
              padding: "5px" // <-- arbitrary value
            },
            /******************* MUI datatable set *******************/

          }}
        />
        <Routes />
      </Fragment>
    </ThemeProvider>
  );
}

export default function App() {
  return (
    <Suspense fallback=''>
      <WrappedApp />
    </Suspense>
  )
}