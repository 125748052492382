import axios from "axios";
const base_url = 'http://pos.tayai.la:4500';
//const base_url = 'http://127.0.0.1:4500';

export default axios.create({
    baseURL: base_url
});

export const axiosPrivate = axios.create({
    baseURL: base_url,
    //headers: { 'Content-Type': 'application/json' },
    //headers: { 'Content-Type': 'multipart/form-data' }
});


export const api_ip = () => {
    return base_url
};